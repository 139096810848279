import React, { useEffect } from 'react';

const AdComponent = ({ client, slot, format, style }) => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div>
      <ins className="adsbygoogle"
        style={style}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-format={format}
        ></ins>
    </div>
  );
};

export default AdComponent;
