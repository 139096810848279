import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer'>
        <div className='d-flex justify-content-between'>
            <a href="https://www.playpokigames.com/privacy-policy">Privacy-policy</a>
            <a href="https://www.playpokigames.com">playpokigames.com</a>
        </div>
    </div>
  )
}

export default Footer