import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from './Pages/Home/Home';

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;
