import React from 'react'
import './Header.css';
import logo from "../../assets/images/logo.png";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

function Header() {
  return (
    <>
      <div className="header">
        <div className="container">
          <div className="row m-0">
            <div className="col-6 col-md-2">
              <a href="https://www.playpokigames.com/">
                <img src={logo} alt="" height={60} />
              </a>
            </div>
            <div className="col-6 col-md-10 d-flex justify-content-end align-items-center ">
              <div className='d-flex '>
                <a href="https://www.facebook.com/profile.php?id=61562091443881&mibextid=LQQJ4d" target='_blank' style={{ color: 'orange' }}><FaFacebookSquare size={20} /></a>
                <a href="https://www.instagram.com/_.pokigames._?igsh=NTF2OGwzbzVpd2Mw&utm_source=qr" target='_blank' style={{ color: 'orange' }}><FaInstagram className='ms-2' size={20} /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header