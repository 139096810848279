import React, { Fragment } from 'react';
import './HomeGames.css';
import AdComponent from '../AdComponent/AdComponent';

function HomeGames() {
    let data = [
        {
            "id": 2,
            "title": "2048",
            "shortUrl": "2048",
            "logo": "2048.webp",
            "banner": "2048.webp",
        },
        {
            "id": 6,
            "title": "Foono Online Multiplayer",
            "shortUrl":"foono-online-multiplayer",
            "logo": "Foono-Online-Multiplayer.webp",
            "banner": "Foono-Online-Multiplayer.webp"
        },
        {
            "id": 9,
            "title": "Wood Block Journey",
            "shortUrl": "wood-block-journey",
            "logo": "Wood-Block-Journey.webp",
            "banner": "Wood-Block-Journey.webp",
        },
        {
            "id": 4,
            "title": "Hyper Jump 3D",
            "shortUrl":"hyper-jump-3d",
            "logo": "hyper-jump-3d.webp",
            "banner": "hyper-jump-3d.webp",
        },
        {
            "id": 84,
            "title": "Hextris",
            "shortUrl":"hextris",
            "logo": "Hextris.webp",
            "banner": "Hextris.webp",
        },
        {
            "id": 16,
            "title": "Racing Limits",
            "shortUrl": "racing-limits",
            "logo": "Racing-Limits.webp",
            "banner": "Racing-Limits.webp",
        },
        {
            "id": 19,
            "title": "Car Clash 2",
            "shortUrl": "car-clash-2",
            "logo": "Car-Clash-2.webp",
            "banner": "Car-Clash-2.webp",
        },
        {
            "id": 55,
            "title": "Bubble Blast",
            "shortUrl":"bubble-blast",
            "logo": "Bubble Blast.webp",
            "banner": "Bubble Blast.webp",
        },
        {
            "id": 21,
            "title": "Crazy Drift",
            "shortUrl": "crazy-drift",
            "logo": "Crazy-Drift.webp",
            "banner": "Crazy-Drift.webp",
        },
        {
            "id": 28,
            "title": "Xtreme DRIFT Racing",
            "shortUrl": "xtreme-drift-racing",
            "logo": "Xtreme-DRIFT-Racing.webp",
            "banner": "Xtreme-DRIFT-Racing.webp",
        }
    ]


    return (
        <>
            <div className='row m-0 g-3'>
            {/* <AdComponent
                                        client="ca-pub-2248973218739803"
                                        slot="1462203285"
                                        format="autorelaxed"
                                        style={{ display: 'block' }}
                                    /> */}
                {
                    data.map((game, index) => {
                        // if (index == 4) {
                        // }
                        return <Fragment key={index}>
                            {index == 4 &&
                                <div className='col-12'>
                                    <AdComponent
                                        client="ca-pub-2248973218739803"
                                        slot="3776487389"
                                        format="auto"
                                        style={{ display: 'block', width: '100%', height: '250px' }}
                                    />
                                </div>
                            } 
                            <div className="col-6">
                                <a href={`https://www.playpokigames.com/game/${game.shortUrl}`} className='link-underline-hide' >
                                    <div className="card gameCard h-100">
                                        <img src={require(`../../assets/images/${game.logo}`)} alt={"Play poki games - " + data.title} />
                                        <div className="card-body p-0 p-1 text-center">
                                            <p className='m-0 title'>{game.title}</p>
                                        </div>
                                        <div className="card-footer">
                                            <button>PLAY</button>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </Fragment>
                    })
                }
            </div>
        </>
    )
}

export default HomeGames